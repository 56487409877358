<template>
  <div>
    <div class="information-limit" :style="{
                color: '#999999',
                'font-size': '12px',
                }">

      <el-form-item
        :label="label"
        :prop="label"
        :rules="[{required:true, validator:(rule,value,callback) => {return callback()}}]"
      >
        <el-upload
          v-if="!disabled"
          :disabled="styleDisable"
          ref="upload"
          v-model="files"
          :action="actionUrl"
          :headers="headers"
          :data="data"
          :show-file-list="false"
          :before-upload="beforeUpload"
          :on-progress="onProgress"
          :on-success="onSuccess"
          :on-error="onError"
          multiple
          :file-list="files"
          :accept="accept"
        >
          <slot name="trigger">
            <button
                class="btn btn-lucency"
                :style="{marginLeft:'0'}"
                @click="preventCode"
              >
              <svg-icon data_iconName="upload" className="upload-icon"></svg-icon>
              {{ btnName }}
            </button>
          </slot>
        </el-upload>
      </el-form-item>
    </div>
    <div class="information-limit" :style="{
                color: '#999999',
                'font-size': '12px',
                'padding-left': '115px',
                }">
      <ul
        class="files-container"
        :class="!!needExplain ? 'needExplain-class' : ''"
      >
        <li
          v-for="file in localFiles"
          :key="file.fileKey"
          :class="file.state"
        >
          <div style="display: flex; width: 90%; position: relative;">
            <div class="content">
              <i :class="fileIcon(file)" />
              <div
                class="file-name"
                :title="file.fileName"
              >
                {{ file.fileName || file.name}}
              </div>
              <div class="extra-info">
                <slot name="extra-info">
                  <div
                    v-if="file.bizCreateDate"
                    class="extra-file-info"
                  >
                    <div class="file-date">{{ file.bizCreateDate  | timeformatter}}</div>
                  </div>
                  <div
                    v-if="file.raw"
                    class="extra-file-info"
                  >
                    <div class="file-date">{{ dateFormat(file.raw.lastModifiedDate)}}</div>
                  </div>
                </slot>
              </div>
              <!-- el-icon-error -->
              <i
                class="el-icon-close delete-button"
                v-if="!showRemark"
                :title="'删除文件'"
                @click.stop="deleteFile(file)"
              ></i>
              <el-progress
                :percentage="file.percent"
                :stroke-width="4"
                :color="customColorMethod(file.state)"
                :show-text="file.state === 'uploading'"
                v-if="file.state === 'uploading'"
                class="file-progress"
              />
            </div>
            <el-col :span="7">
            <div
              class="fileNote"
              v-if="['uploading', 'success'].includes(file.status) && showRemark"
            >
              <el-input
                placeholder="附件备注"
                maxlength="5000"
                show-word-limit
                size="small"
                v-model="file.fileNote"
                @input="modifyNote($event, file)"
              ></el-input>
            </div>
          </el-col>
          </div>
          <div
            class="fileDelete"
            v-if="['uploading', 'success'].includes(file.status) && showRemark"
          >
            <i
              class="el-icon-remove-outline deleteBtn"
              @click="deleteFile(file)"
            ></i>
          </div>
        </li>
      </ul>
      <div class="information-limit" :style="{
                color: '#999999',
                'font-size': '12px',
                'margin-top': localFiles.length > 0 ? '10px' : '-10px',
                }">
          <i class="el-icon-warning-outline"></i>
          支持：.doc .docx .ppt .pptx .pdf .jpg .jpeg .png .xlsx .xls
          .rar .zip 邮件文件,单个文件不能大于200M。
      </div>
    </div>
    </div>
  </template>
  
  <script>
    import moment from 'moment'
    import settings from '@/settings'
    import axios from 'axios'
    const imgSuffix = [
      'webp',
      'tif',
      'gif',
      'jpeg',
      'jpg',
      'svg',
      'psd',
      'png',
      'bmp',
      'ico'
    ]
    const validFileTypes = [
      'doc',
      'docx',
      'xls',
      'xlsx',
      'ppt',
      'pptx',
      'pdf',
      'jpg',
      'jpeg',
      'png',
      'rar',
      'zip',
      'txt',
      'text'
    ]
  
    export const validator = (rule, fileVal, callback) => {
      if (rule.required) {
        if (!fileVal || fileVal.length === 0) {
          callback(new Error('请上传文件'))
          return
        }
      }
      let value = fileVal || []
      if (rule.docType) {
        for (let i = 0; i < value.length; i++) {
          if (!value[i].value) {
            callback(new Error('      '))
            return
          }
        }
      }
      for (let i = 0; i < value.length; i++) {
        if (value[i].state === 'uploading') {
          callback(new Error('文件未上传完成，请稍后再试'))
          return
        }
        if (value[i].state === 'error') {
          callback(new Error('请移除上传失败的文件'))
          return
        }
      // if (value[i].name.split('.').length !== 2) {
      //   callback(new Error('文件后缀名错误'))
      //   return
      // }
      }
  
      callback()
    }
    export default {
      model: {
        prop: 'value',
        event: 'change'
      },
      props: {
        // 上传接口
        actionUrl: {
          type: String,
          default:  settings.isUAT || settings.isDev ? 
            'https://cms-doc-app-uat-usa.platanus.com/doclogic/blob/saveFileToOss' : 
            'https://cms-doc-app-usa.platanus.com/doclogic/blob/saveFileToOss'
        },
        // 上传时附带的额外参数
        data: {
          type: Object,
          default: function () {
            return {
              
            }
          }
        },
        headers: {
          type: Object,
          default: function ()
             {
               return {
                CASTGC:sessionStorage.getItem('castgc')
               }
            }
          
        },
        // 样式禁用 因为disable 被占用
        styleDisable: {
          default: false
        },
        value: {
          type: Array
        },
        docTypes: {
          type: Array
        },
        tip: String,
        czgyydlx: {},
        disabled: {
          type: Boolean,
          default: false
        },
        showRemark: {
          type: Boolean,
          default: false,
        },
        limit: {
          type: Number,
          default: -1
        },
        label: {
          type: String,
          defualt: ""
        },
        // 是否需要输入文件详情
        needExplain: {
          type: Boolean,
          default: false
        },
        accept: {
          type: String,
          default: ''
        },
        // 是否不需要检测类型
        needNoAccept: {
          type: Boolean,
          default: false
        },
        btnName: {
          type: String,
          default: '上传文件'
        },
        descName: {
          type: String,
          default: '文件说明:'
        },
        descLabelWidth: {
          type: String,
          default: '116px'
        },
        fileCategory: {
          type: String,
          defualt: 'CONTRACT'
        },
        needLabel: {
          type: Boolean,
          default: false
        },
        labelName: {
          type: String,
          default: '文件：'
        },
        needDivder: {
          type: Boolean,
          default: true
        },
        selectPlaceholder: {
          type: String,
          default: '请选择'
        },
        descPlaceholder: {
          type: String,
          default: '请输入内容'
        },
        setDefalut: Boolean,
        maxlength: {
          type: Number,
          default: 2000
        },
        selectWidth: {
          type: String,
          default: '120px'
        },
        fileUploadList: {
          type: Array,
          default: ()=>{
            return []
          }
        },
        updateFileList:Function,
      },
      watch: {
        value: {
          handler: function(val, oldVal) {
            if (val) {
              if (val !== this.localFiles) {
                this.localFiles.forEach(file => {
                  file.state === 'uploading' &&
                    this.$refs.upload.abort(file.tempFile)
                })
                val.forEach(item => {
                  item.state = 'done'
                })
                this.files = [...val]
                this.localFiles = [...val]
              }
            } else {
              if (oldVal) {
                this.files = []
                this.localFiles = []
              }
            }
          },
          immediate: true
        }
      },
      filters: {
        timeformatter(v) {
          return v ? moment(v).format('YYYY-MM-DD') : '-'
        }
      },
      data() {
        return {
          files: [],
          localFiles: []
        }
      },
      beforeDestroy() {
        this.localFiles.forEach(file => {
          if (file.state === 'uploading') {
            this.$refs.upload.abort(file.tempFile)
          }
        })
      },
      methods: {
        preventCode(e) {
        e.preventDefault();
      },
        customColorMethod(state) {
          if (state === 'error') return '#f56c6c'
          return '#409eff'
        },
        timeformatter(v) {
          return v ? moment(v).format('YYYY-MM-DD') : '-'
        },
        fileIcon(file) {
          if (file.state === 'uploading') {
            return 'el-icon-loading file-icon'
          }
          if (file.state === 'error') {
            return 'el-icon-warning file-icon'
          }
  
          if (!file.iconType) {
            // console.log(file)
            const suffix = (
              (file.name || file.fileName || '').substr((file.name || '').lastIndexOf('.') + 1) || ''
            ).toLocaleLowerCase()
            if (imgSuffix.includes(suffix)) {
              file.iconType = 'el-icon-picture-outline file-icon'
            } else {
              file.iconType = 'el-icon-document file-icon'
            }
          }
  
          return file.iconType
        },
        dateFormat(date){
          return date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()
       },
        modifyNote(event, file){
          file.fileNote = event
        },
        findFile(file) {
          for (let aFile of this.localFiles) {
            if (aFile.tempFile && aFile.tempFile.uid === file.uid) {
              return aFile
            }
          }
          return null
        },
        beforeUpload(file) {
          if (this.limit > 0) {
            if (this.localFiles.length >= this.limit) {
              this.$sMessage.warning('最多上传' + this.limit + '个文件')
              return false
            }
          }
          let num = file.name.lastIndexOf('.')
          let str = file.name.substring(num + 1)
          if (!this.needNoAccept) {
            if (!validFileTypes.includes(str.toLocaleLowerCase())) {
              this.$sMessage.warning('文件格式错误')
              return false
            }
          }
          if (this.accept.length) {
            let acceptArr = this.accept.split(',')
            if (
              /xls/.test(this.accept.toLocaleLowerCase()) &&
              !acceptArr.includes('.' + str.toLocaleLowerCase())
            ) {
              this.$message.warning(`仅支持excel格式的附件, 而且每个文件不超过5000行`)
              return false
            }
          }
          if (file.size > 209715200) {
            this.$message.warning('上传文件大小最大为200MB')
            return false
          }
          const localFile = {
            name: file.name,
            url: '',
            state: 'uploading',
            tempFile: file,
            percent: 0
          }
          if (this.setDefalut) {
            localFile.value = this.docTypes[0] && this.docTypes[0].dictCode
          }
          this.localFiles.unshift(localFile)
          this.changeEvent(true)
          this.$emit('beforeUpload', file)
        },
        onSuccess(response, file) {
          const apiEndPoint = settings.isUAT || settings.isDev ? "https://cms-doc-app-uat-usa.platanus.com/doclogic/blob/saveFileToDb" : "https://cms-doc-app-usa.platanus.com/doclogic/blob/saveFileToDb"
          axios.post(
              apiEndPoint,
            { 
              "fileName": response.data.fileName,
              "bucketName": "shcms",
              "folderId": "1402084802252767232",
              "fileSize": response.data.fileSize,
              "objectName": response.data.objectName,
              "previewUrl":response.data.previewUrl
            },
            {
              headers: {
                CASTGC:sessionStorage.getItem('castgc')
              },
            }
          ).then((res) => {
            file.bizCreateDate = null
            file.bizCreateDateStr = null
            file.bizId = null
            file.bizUpdateDate = null
            file.bizUpdateDateStr = null
            file.createDate = null
            file.createUser = null
            file.createUserName = null
            file.dataType = null
            file.deleteFlag = null
            file.fileServicePath = null
            file.fileType = null
            file.iconTyp = null
            file.id = null
            file.operatorNo = null
            file.orgId = null
            file.sourceId = null
            file.updateDate = null
            file.updateUser = null
            file.updateUserName = null
            file.version = 1
            file.fileId = res.data.fileId
            file.docLibId = res.data.fileId
            file.fileName = res.data.fileName
            file.fileRealName = res.data.realName
            file.fileCategory = this.fileCategory
            file.deleteFlag = false
            this.updateFileList(file, this.fileCategory)
            this.localFiles = [...this.localFiles.filter(f => {
              if(f.status && f.status == 'success') {
                return true
              }
            }), file]
          })
          this.changeEvent()
        },
  
        onProgress(event, file) {
          const aFile = this.findFile(file)
          if (aFile) {
            aFile.percent = event.percent | 0
          }
        },
        onError(_, file) {
          const aFile = this.findFile(file)
          if (aFile) {
            aFile.percent = 100
            aFile.state = 'error'
          }
          this.changeEvent()
        },
        deleteFile(file) {
          if (file.state === 'uploading') {
            this.$refs.upload.abort(file.tempFile)
          }
  
          if (file.id) {
            // 已有的文件
            this.localFiles = this.localFiles.filter(
              aFile => !aFile.id || (aFile.id !== file.id)
            )
          } else {
            // 上传中/上传失败的文件
            this.localFiles = this.localFiles.filter(
              aFile => !aFile.raw || (aFile.raw.uid !== file.raw.uid)
            )
          }
          this.changeEvent()
        },
        changeEvent(skip) {
          this.$emit('change', this.localFiles)
          if (skip) return
          if (this.$parent && this.$parent.$parent) {
            this.$parent.$parent.validate &&
              this.$parent.$parent.validateField(this.$parent.prop)
          }
          if (
            this.$parent &&
            this.$parent.$parent &&
            this.$parent.$parent.$parent
          ) {
            this.$parent.$parent.$parent.validate &&
              this.$parent.$parent.$parent.validateField(this.$parent.prop)
          }
          if (
            this.$parent &&
            this.$parent.$parent &&
            this.$parent.$parent.$parent &&
            this.$parent.$parent.$parent.$parent
          ) {
            this.$parent.$parent.$parent.$parent.validate &&
              this.$parent.$parent.$parent.$parent.validateField(this.$parent.prop)
          }
        }
      }
    }
  </script>
  
  <style lang="scss" scoped>
  .files-container {
    width: 100%;
    margin-top: 8px;
  }
  .needExplain-class {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // ul{
    //   display: flex;
    //   flex-direction: column;
    // }
    li {
      flex-direction: column;
      width: 100%;
      height: auto;
      justify-content: flex-start;
      align-items: flex-start;
      .content {
        width: 750px;
      }
      .form-explain {
        margin-left: -116px;
        padding-top: 15px;
        width: 100%;
        .form-explain-item {
          width: 100%;
          /deep/ .el-form-item__content {
            width: 735px;
          }
        }
      }
      .demo-ruleForm {
        width: 100%;
      }
    }
    /deep/ .el-divider--horizontal {
      margin: 20px 0 0 0;
    }
  }
  li {
    width: 100%;
    display: flex;
    align-items: center;
    height: 32px;
    line-height: 32px;
    margin-bottom: 14px;
    position: relative;
    background-color: white;
    border-radius: 2px;
    box-sizing: border-box;
  
    &:last-child {
      margin-bottom: 0;
    }
  
    .content {
      position: relative;
      border-radius: 2px;
      border: 1px solid #d6d6d6;
      line-height: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      width: 0;
    }
    .file-label {
      position: absolute;
      left: -35px;
      font-size: 12px;
    }
  }
  .upload-icon{
      font-size: 24px;
      vertical-align: middle;
      margin-bottom: 2px;
    }

  .file-icon {
    margin: 0 8px;
    color: #d6d6d6;
  }
  
  .file-name {
    color: #323232;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
  }
  
  .extra-info {
    height: 30px;
    overflow: hidden;
  }

  .fileDelete {
      width: 40px;
      height: 40px;
      text-align: center;
      margin-left: 5px;
      .deleteBtn {
        color: #fa5f87;
        font-size: 26px;
        line-height: 40px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  
  .delete-button {
    color: #999;
    margin-right: 8px;
    // visibility: hidden;
    cursor: pointer;
  }
  
  li.done {
    .file-name {
      color: #323232;
    }
  }
  
  li.uploading {
    .file-name {
      color: #999999;
    }
  }
  
  li.error {
    .content {
      border: 1px solid #fa5f87;
      .file-icon,
      .file-name {
        color: #f56c6c;
      }
    }
  }
  
  .content:hover {
    border-color: #efd193;
  
    /deep/ .el-progress__text {
      visibility: hidden;
    }
  
    .delete-button {
      visibility: visible;
    }
  }
  
  .file-progress {
    position: absolute;
    bottom: 1px;
    left: 0;
    height: 4px;
    width: 100%;
    line-height: 4px;
  
    /deep/ .el-progress-bar {
      padding-right: unset;
      margin-right: unset;
    }
  
    /deep/ .el-progress__text {
      position: absolute;
      right: 4px;
      height: 22px;
      bottom: 4px;
      line-height: 26px;
      color: #409eff;
      background: white;
    }
  }
  
  .doc-type-select {
    margin-right: 12px;
    flex-shrink: 0;
    /deep/ .el-input__inner {
      border: 1px solid #d6d6d6;
    }
  }
  
  .select-error-tip {
    color: #fa5f87;
    font-size: 12px;
    position: absolute;
    top: 23px;
    left: 0;
    visibility: hidden;
  }
  
  .el-form-item.is-error {
    .select-error-tip {
      &.error {
        visibility: visible;
      }
    }
  
    .doc-type-select {
      &.error {
        /deep/ .el-input__inner {
          border: 1px solid #fa5f87;
  
          &::placeholder {
            color: #fa5f87;
          }
        }
      }
  
      &.pass {
        /deep/ .el-input__inner {
          border: 1px solid #d6d6d6;
        }
      }
    }
  }
  .fileNote {
    height: 25px;
    margin-left: 12px;
  }
  .extra-file-info {
    display: flex;
    font-size: 12px;
    color: #999999;
    margin-left: 12px;
    .user-name {
      width: 72px;
    }
    .file-date {
      width: 80px;
    }
  }
  /deep/.styleSelf2 {
    font-size: 14px;
  }
  /deep/.site-icon {
    font-size: 14px;
  }
  .upload-tip {
    position: relative;
    margin-top: 12px;
    height: 18px;
    line-height: 18px;
    font-size: 12px;
    color: #999999;
    i {
      position: absolute;
      top: 2px;
    }
    .tip {
      white-space: nowrap;
      padding-left: 22px;
      display: inline-block;
    }
  }
  </style>
  